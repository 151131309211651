<template>
  <div class="container-fluid position-relative">
        <b-row>
            <b-col cols="7">
                <b-overlay :show="showOverlay" rounded="sm">
                    <b-card>
                        <div class="m-auto w-100 d-flex justify-content-center">
                            <paginator :offset="offset" :total="trueOccupations.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />
                        </div>
                        <hr>
                        <b-form-checkbox :disabled="submitted" v-model="allSelected" @change="toggleAll">{{ allSelected ? 'Tout deselectionner' : 'Tout selectionner' }}</b-form-checkbox>
                        <div class="mt-3 w-100 d-flex justify-content-between flex-wrap">
                            <b-list-group-item class="w-50" v-for="(item, i) in items" :key="item.idOccupation || i">
                                <label class="d-flex justify-content-between align-items-center w-100">
                                    <b-avatar :src="item.locataire.avatar" />
                                    <div style="flex: 1" class="ml-2">
                                        <h6 class="mb-0">{{ item.locataire.nomLocataire + ' ' + item.locataire.prenomLocataire }}</h6>
                                        <h6 class="mt-0 text-muted">{{ item.logement.refLogement }} / {{ item.locataire.tel }}</h6>
                                    </div>
                                    <input :disabled="submitted" type="checkbox" v-model="item.selected" />
                                </label>
                            </b-list-group-item>
                        </div>
                    </b-card>
                </b-overlay>
            </b-col>
            <b-col cols="5">
                <b-card>
                    <b-form-textarea v-model="message" placeholder="Saisissez votre message ici"  rows="22"/>
                    <b-button block variant="danger" class="mt-4" :disabled="submitted" @click="sendMessage">Envoyer <b-spinner small v-if="submitted"/></b-button>
                </b-card>
            </b-col>
      </b-row>
  </div>
</template>
<script>
  const php  = require ( 'phpjs' ) ; 
export default {
  name: "Communique",
  components: {
  },
  data: () => ({
      allSelected: false,
      submitted: false,
      showOverlay: true,
      message: '',


 currentPage: 1,
     perPage: 10,
      occupations:[],
     trueOccupations:[],
  }),
  computed: {
        /**
         * Elements affichés avec prise en charge de la pagination
         */
        items() { 
            return php.array_slice(this.occupations, this.offset, this.perPage) 
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },

        selectedNumber() {
            return this.trueOccupations.filter(elt => elt.selected).map(elt => elt.locataire.tel)
        }
    },
    mounted() {
        this.getOccupations()
    },
    methods: {
        getOccupations() {
            this.showOverlay = true
            axios.get('occupations').then(response => response.result || []).then(occupations => {
                this.occupations = this.trueOccupations = occupations.map(elt => ({...elt, selected: false})).sort((a, b) => {
                    if (a.dateFin == null) {
                        return -1
                    }
                    return this.$dayjs(b.dateFin).diff(a.dateFin)
                }).sort((a, b) => {
                    if (a.logement.refLogement < b.logement.refLogement) {
                        return -1
                    }
                    return 1
                })
                this.showOverlay = false
            });
        },

        toggleAll(checked) {
            this.trueOccupations = this.trueOccupations.map(elt => ({...elt, selected: checked}))
            this.occupations = this.occupations.map(elt => ({...elt, selected: checked}))
        },


        sendMessage() {
            if (!this.selectedNumber.length) {
                return App.error("Veuillez selectionner au moins un locataire")
            }
            if (php.empty(this.message)) {
                return App.error("Veuillez entrer le message à envoyer")
            }
            const data = {
                message: this.message,
                numbers: this.selectedNumber //[   '657006011',     '693803726', '658097020','692322373', '693325331']
            }
            this.submitted = true
            
            axios.post('tasks/bulck-sms', data).then(response => {
                this.submitted = false
              /*   this.message = ''
                this.toggleAll(false)
                this.allSelected = false
               */  App.alertSuccess(response.message)
            }).catch(error => {
                this.submitted = false
                App.notifyError(error.message)
            })
        }

    }

};
</script>